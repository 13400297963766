<template>
    <ul class="list-unstyled pl-100">
        <li>
            <ds-link to="/examples/form-field-validation"> Form with client-side validation </ds-link>
        </li>
        <li>
            <ds-link to="/examples/form-validation"> Form with server error </ds-link>
        </li>
    </ul>
</template>
